<template>
  <div v-if="currentItem" class="my-2 align-items-center">
    <!-- CRM Object selection -->
    <div v-if="!!objectDefinitions" class="my-1 row align-items-center">
      <div class="col-2">{{ staticText.CRMObject }}:</div>

      <div class="col-10 d-flex">
        <bao-vue-multi-select
          class="multiselect__custom"
          :id="'linked-crm-object-selection-' + currentItem.uniqueId"
          :value="currentItem.linked_field.crm_object_link"
          :options="counterpartObjectDefinitions"
          :show-labels="false"
          :custom-label="item => item.label"
          :disabled="!canModify"
          track-by="id"
          placeholder="Select CRM object"
          @select="selectedValue => getSelectedObject(currentItem, selectedValue)"
        >
          <template #option="{ option }">
            <div class="d-flex justify-content-between">
              <div>{{ option.label }}</div>

              <component
                :is="getCrmLogo(option.service_key)"
                class="crm-logo crm-logo__slate"
              />
            </div>
          </template>

          <template #singleLabel="{ option }">
            <div class="d-flex justify-content-between">
              <div>
                {{ option.label }}
              </div>

              <component
                :is="getCrmLogo(option.service_key)"
                class="crm-logo crm-logo__slate"
              />
            </div>
          </template>
        </bao-vue-multi-select>
      </div>
    </div>

    <!-- CRM Field selection-->
    <div
      v-if="currentItem.linked_field.crm_object_link"
      class="my-2 align-items-center row"
    >
      <div class="col-2 my-auto">{{ staticText.CRMField }}:</div>

      <div class="col-10 d-flex">
        <div v-if="isFieldDefinitionLoading(currentItem)" class="w-100">
          <progress-indicator :small="true"></progress-indicator>
        </div>

        <!-- Field Definition Dropdown -->
        <div class="flex-grow-1 multiselect__custom">
          <bao-vue-multi-select
            :id="'field-definition-selection-' + currentItem.uniqueId"
            v-if="!!getFieldDefinition(currentItem)"
            :options="getFieldDefinition(currentItem)"
            v-model="selectedFieldDefinitions"
            :show-labels="false"
            :custom-label="item => item.label"
            :placeholder="staticText.selectCRMObjectField"
            :disabled="!canModify"
            track-by="id"
            :multiple="true"
            @input="selectedCRMField"
          >
            <template slot="option" slot-scope="{ option }">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.label }}
                </div>

                <div class="alert-warning">
                  <div v-if="option.readonly" class="p-1">
                    {{ staticText.readOnly }}
                  </div>

                  <div v-else-if="!option.supported" class="p-1">
                    {{ staticText.notSupported }}
                  </div>
                </div>
              </div>
            </template>
          </bao-vue-multi-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { mapGetters } from "vuex"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"

export default {
  data () {
    return {
      selectedFieldDefinitions: null,
      staticTextDefault: {
        CRMObject: "CRM Object",
        CRMField: "CRM Field(s)",
        selectCRMObjectField: "Select CRM object field",
        readOnly: "Read-only",
        notSupported: "Not supported"
      }
    }
  },
  mixins: [CrmLogoMixin],
  components: {
    BaoVueMultiSelect,
    ProgressIndicator
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: true
    },
    currentItem: {
      type: Object,
      default: () => {
      },
      required: true
    },
    canModify: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  mounted () {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      objectDefinitions: "crmStore/getObjectDefinitions",
      fieldDefinitions: "crmStore/getFieldDefinitions",
      loadingFieldDefinitions: "crmStore/getLoadingFieldDefinitions"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    counterpartObjectDefinitions () {
      return this.objectDefinitions.filter(object => object.is_counterpart)
    }
  },
  methods: {
    setInitialData () {
      if (this.currentItem && this.currentItem.linked_field && this.currentItem.linked_field.field_definition) {
        this.selectedFieldDefinitions = this.currentItem.linked_field.field_definition
      }
    },
    getSelectedObject (item, selectedValue) {
      if (item.linked_field.field_definition) {
        delete item.linked_field.field_definition
        this.selectedFieldDefinitions = null
      }
      this.$set(item.linked_field, "crm_object_link", selectedValue)
      this.$emit("get-selected-object", selectedValue)
    },
    isFieldDefinitionLoading (item) {
      return this.loadingFieldDefinitions.indexOf("" + item.linked_field.crm_object_link.id) >= 0
    },
    getFieldDefinition (currentItem) {
      try {
        return this.fieldDefinitions[currentItem.linked_field.crm_object_link.id]
      } catch (error) {
        console.debug(error)
      }
    },
    selectedCRMField () {
      this.$set(this.currentItem.linked_field, "field_definition", this.selectedFieldDefinitions)
      this.$emit("selected-crm-field", this.currentItem.linked_field)
    }
  }
}
</script>

<style scoped lang="scss">

.crm-logo {
  width: 20px;
  height: 20px;
}

.multiselect__custom {
  &:deep(.multiselect__tags) {
    padding-bottom: 12px;

    & .multiselect__tag-icon {
      margin-top: 4px;
    }
  }
}

</style>
