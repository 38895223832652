<template>
  <div :class="getPlaybookItemClasses"
       v-b-hover="handleItemHover"
  >
    <!-- Progress indicator for playbook item loading -->
    <div
      v-if="!currentItem.uniqueId || currentItem.loading"
      class="w-100 d-flex justify-content-between"
    >
      <progress-indicator></progress-indicator>
    </div>

    <!-- Playbook Item loaded -->
    <div v-else>
      <!-- header - drag handle and remove button -->
      <div
        :class="[
          'item-header',
          {'item-header-close': !currentItem.open},
          {'cursor-pointer': !isOpenFromLibraryItemsModal}
        ]"
        @click="onHeaderClick(currentItem)"
      >
        <!-- Error display for each item -->
        <div @click.stop>
          <base-alert
            v-if="!!currentItem.error"
            :alert-message="currentItem.error"
            :dismissible="true"
            :show-alert="!!currentItem.error"
            class="mb-1"
            variant="danger"
            @dismissed="resetError(currentItem)"
          ></base-alert>
        </div>

        <!-- Drag icon (show only if multiple playbook items) -->
        <div
          :class="[PLAYBOOK_ITEM_DRAGGABLE_HANDLE, 'd-flex justify-content-center mb-n4 pt-1 cursor-grab']"
          v-if="showDragIcon"
        >
          <img src="/img/icons/drag-btn.svg">
        </div>

        <div class="d-flex justify-content-between">
          <!-- Playbook item title -->
          <div class="d-flex my-auto"> <!--left section-->
            <img class="mr-1" src="/img/icons/link-file-icon.svg"/>
            <bao-editable-text
              v-model="currentItem.name"
              :disabled="!canBeEdited"
              :extra-style="editableTextExtraStyle"
              :max-character-limit="120"
              :max-character-limit-message="staticText.itemNameMaxCharacterLimitMessage"
              @input-changed="title => handlePlaybookItemTitleChanged(currentItem, title)"
            ></bao-editable-text>
          </div>

          <!-- Playbook Item type and Controls -->
          <div class="d-flex align-items-center">
            <playbook-item-controls
              :can-modify="canBeEdited"
              :item-types="itemTypes"
              :current-item="currentItem"
              :is-open-from-library-items-modal="isOpenFromLibraryItemsModal"
              @handle-playbook-item-changed="handlePlaybookItemChanged"
            />
            <!-- Close button (displayed on library items) -->
            <slot name="close-btn"></slot>
          </div>

        </div>
      </div>

      <!-- Content -->
      <b-collapse
        v-model="currentItem.open"

      >
        <div class="container-flex item-content">
          <!-- Playbook item display text -->
          <rich-text-editor
            v-if="textAvailableOrCanBeEdited(currentItem.display_text)"
            :id="'item-display-text-' + currentItem.uniqueId"
            v-model="currentItem.display_text"
            :editable="canBeEdited"
            :placeholder="staticText.richTextPlaceholder"
            :dynamic-data="true"
            class="mt-2 mb-1"
            @input="handlePlaybookItemChanged(currentItem)"
          ></rich-text-editor>

          <!-- Additional Playbooks -->
          <additional-playbooks
            v-if="isItemType(currentItem, 'playbookloader')"
            :can-modify="canBeEdited"
            :current-item="currentItem"
            @handle-playbook-item-changed="handlePlaybookItemChanged"
          />
          <!-- End of Additional Playbooks-->

          <!-- Linked item -->
          <linked-item
            v-if="isItemType(currentItem, 'crmlink') && currentItem.linked_field"
            :can-modify="canBeEdited"
            :index="index"
            :current-item="currentItem"
            @get-selected-object="getSelectedObject"
            @selected-crm-field="linked_field => selectedCRMField(currentItem, linked_field)"
          />
          <!-- End of Linked Item -->

          <!-- Question item -->
          <div v-if="isItemWithAnswers(currentItem.item_type)">
            <answers-configurator
              :id="'item-answers-' + index"
              :can-modify="canBeEdited"
              :item="currentItem"
              class="my-2"
              @error="error => $emit('emit-error', error, currentItem)"
              @answer-choices-changed="
                answerChoices =>
                  handleAnswerChoicesChanged(currentItem, answerChoices)
              "
            ></answers-configurator>
          </div>
          <!-- End of  Question item -->

          <!-- Shortcuts -->
          <div v-if="canHaveShortcuts(currentItem)" class="my-3">
            <hr class="mb-3">
            <shortcuts-configurator
              :can-modify="canBeEdited"
              :main-container="currentItem"
              :shortcuts-on-item="true"
              :item-index="index"
              @error="error => $emit('emit-error', error, currentItem)"
              @input="
                shortcuts => handleShortcutsChanged(currentItem, shortcuts)
              "
              @update-last-saved="$emit('update-last-saved')"
            ></shortcuts-configurator>
          </div>

          <!-- Additional custom fields for task creator -->
          <additional-task-fields-component
            v-if="
              isItemType(currentItem, 'datetimepicker') &&
                !!(this.getCrmObjectId) &&
                canBeEdited
            "
            :item="currentItem"
            @change="crmItems => handleCrmItemsChanged(currentItem, crmItems)"
          >
          </additional-task-fields-component>
        </div>

        <hr class="my-auto">

        <!-- Playbook Item Settings -->
        <playbook-item-settings
          :index="index"
          :base-url="baseUrl"
          :can-modify="canBeEdited"
          :main-container="mainContainer"
          :selected-objection="selectedObjection"
          :root-container="rootContainer"
          :current-item="currentItem"
          :is-open-from-library-items-modal="isOpenFromLibraryItemsModal"
          :isOpenFromShortcutsModal="isOpenFromShortcutsModal"
          @handle-playbook-item-changed="handlePlaybookItemChanged"
          @transform-item="transformItem"
          @emit-error="error => $emit('emit-error', error, currentItem)"
          @delete-item="handlePlaybookItemDelete(currentItem, index)"
          @order-was-changed="$emit('order-was-changed')"
          @shortcut-deleted="$emit('shortcut-deleted')"
          @handle-version-changed="item => $emit('handle-version-changed', item)"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import ShortcutsConfigurator from "./components/ShortcutsConfigurator"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import RichTextEditor from "../richtexteditor/RichTextEditor"
import AnswersConfigurator from "@/apps/talkscript/components/AnswersConfigurator"
import AdditionalTaskFieldsComponent from "@/apps/talkscript/components/AdditionalTaskFieldsComponent"
import AdditionalPlaybooks from "./components/AdditionalPlaybooks"
import PlaybookItemSettings from "./components/PlaybookItemSettings"
import LinkedItem from "./components/LinkedItem"
import PlaybookItemControls from "./components/PlaybookItemControls"
import { playbookServices } from "@/apps/talkscript/PlaybookServices"
import { mapGetters, mapMutations } from "vuex"
import BaseAlert from "@/apps/base/BaseAlert"
import BaoEditableText from "@/apps/base/BaoEditableText"
import { PLAYBOOK_ITEM_DRAGGABLE_HANDLE } from "./components/utils"

export default {
  name: "PlaybookItem",
  data () {
    return {
      currentItem: {},
      PLAYBOOK_ITEM_DRAGGABLE_HANDLE,
      isActiveItem: false,
      staticTextDefault: {
        itemNamePlaceholder: "Item name",
        richTextPlaceholder: "Enter text to be displayed",
        itemNameMaxCharacterLimitMessage: "The item name cannot exceed 120 characters."
      }
    }
  },
  components: {
    BaseAlert,
    AnswersConfigurator,
    ProgressIndicator,
    RichTextEditor,
    AdditionalTaskFieldsComponent,
    AdditionalPlaybooks,
    PlaybookItemSettings,
    LinkedItem,
    PlaybookItemControls,
    BaoEditableText,
    ShortcutsConfigurator
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: false
    },
    value: {
      type: Object,
      default: () => {
      },
      required: true
    },
    canBeEdited: {
      type: Boolean,
      required: true
    },
    mainContainer: { // parent of the playbook items
      type: Object,
      default: () => {
      },
      required: false
    },
    selectedObjection: {
      type: Object
    },
    itemTypes: {
      type: Array,
      required: true
    },
    isOpenFromLibraryItemsModal: {
      type: Boolean,
      default: false,
      required: false
    },
    isOpenFromShortcutsModal: {
      type: Boolean,
      default: false,
      required: false
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getCrmObjectId: "crmStore/getCrmObjectId",
      rootContainer: "playbookConfiguratorStore/getRootContainer",
      activeItem: "playbookConfiguratorStore/getActiveItem"
    }),
    isActiveItemComputed () {
      return this.isActiveItem || (this.activeItem && this.activeItem.id && this.currentItem.id && this.activeItem.id === this.currentItem.id)
    },
    showDragIcon () {
      return this.mainContainer && this.mainContainer.children ? this.mainContainer.children.length > 1 : false
    },
    getPlaybookItemClasses () {
      return [
        "component-container",
        "container-fluid",
        "item-wrapper",
        "my-0",
        "p-0",
        { active: this.isActiveItemComputed && !this.isOpenFromLibraryItemsModal }
      ]
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    editableTextExtraStyle () {
      const maxWidth = this.isOpenFromLibraryItemsModal ? "400px" : "200px"
      return { "max-width": maxWidth }
    }
  },
  watch: {
    value (val) {
      // This is to check that the object exists and it's not empty
      // Else transformItem would throw an error
      if (val && Object.keys(val).length !== 0) this.currentItem = this.transformItem(val, this.mainContainer)
    }
  },
  mounted () {
    this.currentItem = this.transformItem(this.value, this.mainContainer)
  },
  methods: {
    ...mapMutations({
      setActiveItem: "playbookConfiguratorStore/setActiveItem"
    }),
    ...playbookServices.methods,
    handleItemHover (isHovered) {
      if (isHovered) {
        this.isActiveItem = true
        if (this.currentItem.id) this.setActiveItem(this.currentItem)
        else this.setActiveItem({})
      } else {
        this.isActiveItem = false
        this.setActiveItem({})
      }
    },
    onHeaderClick (item) {
      if (this.isOpenFromLibraryItemsModal) return false
      item.open = !item.open
    },
    resetError (item) {
      this.$emit("emit-error", null, item)
    },
    handlePlaybookItemDelete (item, itemIndex) {
      this.$emit("delete-item", itemIndex)
      this.updateDynamicDataInput(item, { delete: true })
    },
    handlePlaybookItemChanged (item) {
      this.currentItem = item
      this.$emit("handle-playbook-item-changed", item)
    },
    getSelectedObject (val) {
      this.$emit("get-selected-object", val)
    },
    selectedCRMField (item, val) {
      item.linked_field = val
      this.handlePlaybookItemChanged(item)
    },
    handleAnswerChoicesChanged (item, answerChoices) {
      item.answer_choices = answerChoices
      this.handlePlaybookItemChanged(item)
    },
    isItemType (item, type) {
      return item.item_type === type
    },
    handleCrmItemsChanged (item, crmItems) {
      item.crm_items = crmItems
      this.handlePlaybookItemChanged(item)
    },
    isItemWithAnswers (itemType) {
      const itemTypesWithAnswers = [
        "question", "rated_multiselect", "single_select_radio", "single_select_dropdown", "multi_select_dropdown"
      ]
      return itemTypesWithAnswers.includes(itemType)
    },
    handlePlaybookItemTitleChanged (item, newTitle) {
      item.name = newTitle
      this.handlePlaybookItemChanged(item)
      this.updateDynamicDataInput(item)
    },
    updateDynamicDataInput (playbookItem, action = { rename: true }) {
      /**
       if playbook item name is changed or deleted, we go through all the playbook items inside playbook including
       playbook items inside objection container and find and replace referenced playbook item name inside
       dynamic placeholders inside playbook item's display_text with new name/text.
       **/
      const playbookItems = this.rootContainer.children
      const objectionItems = this.rootContainer.objections.flatMap(objection => objection.workflow.children)
      const allItems = playbookItems.concat(objectionItems)
      allItems.forEach(item => this.updateDisplayText(item, playbookItem, action)
      )
    },
    updateDisplayText (itemWithDisplayText, updatedItem, action) {
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = itemWithDisplayText.display_text

      const spanElements = tempDiv.querySelectorAll("span[data-id]")
      if (spanElements.length > 0) {
        for (const spanElement of spanElements) {
          const dataId = spanElement.getAttribute("data-id").slice(2, -2).split(",")
          if (dataId.length >= 3 && dataId[2] === String(updatedItem.id)) {
            // expected data-id is {{dataType, Label, ItemId, ...}}, means we have playbook item id as third element
            const splitter = dataId[1].includes(" von ") ? " von " : " from "
            const oldDataLabel = dataId[1].split(splitter)
            const identifier = dataId.slice(2)
            const dataType = dataId[0]

            let itemNewName
            if (oldDataLabel.length > 1) {
              if (action.delete) {
                itemNewName = splitter === " von " ? "(gelöschtes item)" : "(deleted item)"
              } else if (action.rename) {
                itemNewName = updatedItem.name
              }
              const newDataLabel = [oldDataLabel[0], splitter.trim(), itemNewName].join(" ")
              const newDataId = [dataType, newDataLabel, ...identifier].join(",")
              spanElement.setAttribute("data-id", `{{${newDataId}}}`)
              spanElement.innerHTML = `{{${newDataLabel}}}`
              itemWithDisplayText.changeDisplayText = true
            }
          }
        }
        if (itemWithDisplayText.changeDisplayText) {
          itemWithDisplayText.display_text = tempDiv.innerHTML
          this.handleSavePlaybookItem(itemWithDisplayText, 0)
        }
      }
    },

    canHaveShortcuts (item) {
      return !!item.objectionFlag && !this.isItemType(item, "playbookloader")
    },
    handleShortcutsChanged (item, shortcuts) {
      item.objections = shortcuts
      this.handlePlaybookItemChanged(item)
    },
    textAvailableOrCanBeEdited (displayText) {
      return !!displayText || this.canBeEdited
    }
  }
}
</script>

<style lang="scss" scoped>

.item-header {
  color: $slate;
  padding: 12px 22px;
  background-color: $grey;
  border-radius: 12px 12px 0 0;
}

.item-header-close {
  border-radius: 12px;
}

.item-content {
  padding: 8px 32px;
}

.item-title {
  padding: 1rem !important;
  height: 2.8em !important;
  min-width: 220px !important;
}

.item-wrapper {
  margin-top: 16px;
  margin-bottom: 32px;
  color: $slate;
  background-color: $white60;
  border-radius: 12px;
  border: 1px solid transparent;
  transition: 0.3s ease-in;

  &.active {
    border-color: $brown80;
  }
}

.cursor-grab {
  cursor: grab !important;
}

</style>
